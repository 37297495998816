html, body, #valota_container {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  position: absolute;
  overflow: hidden;
  font-family: "Abel", sans-serif;
}

body.unpaid > div#valota_container {
  height: calc(100% - 10vmin);
}

body.unpaid div.unpaidMarquee {
  animation: scroll 14s linear 0s infinite;
}

div.unpaidMarquee {
  position: absolute;
  bottom: 0;
  height: 10vmin;
  width: 100vw;
  overflow: hidden;
}

div.unpaidMarquee.leftSide {
  left: 100%;
}

body:not(.preview):not(.browser-display), #valota_container:not(.preview) {
  cursor: none;
}

body.disableTransitions, body.disableTransitions * {
  transition: none !important;
}

#overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: none;
}

#overlay > iframe {
  height: 100%;
  width: 100%;
  cursor: none;
  border: 0;
  padding: 0;
  margin: 0;
  position: absolute;
  background-color: transparent;
  z-index: 9999;
}

#valota_emergency {
  display: none;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  background-color: #e50019;
  z-index: 10000;
  width: calc(100% - 6vmin);
  height: calc(100% - 6vmin);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 3vmin;
}

#display_off {
  background-color: white;
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 9000;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
}

#display_off > div#owner_logo_off {
  width: 85vw;
  height: 30vh;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

#display_off > h3 {
  padding-top: 5vh;
  text-align: center;
  text-transform: initial;
}

#display_off > h1 {
  text-align: center;
}

h1 {
  color: rgba(0, 0, 0, 0.7);
  font-weight: 300;
  font-size: 10vmin;
  margin: 0.2em;
}

h3 {
  color: rgba(0, 0, 0, 0.7);
  font-weight: 300;
  font-size: 3vmin;
  padding: 0.2em;
  margin: 0.2em;
  text-transform: uppercase;
}

#input_display_name, #input_pin, #claim_pin {
  text-align: center;
  font-size: 5vmin;
  font-family: "Abel", sans-serif;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  color: rgba(0, 0, 0, 0.8);
  padding: 0.4em;
  box-sizing: border-box;
  border: 0;
}

#claim_pin {
  font-family: monospace;
}

#input_pin {
  font-size: 10vmin;
}

#pin_btn {
  font-size: 2em;
}

input:focus {
  outline: none;
}

input::placeholder {
  color: rgba(0, 0, 0, 0.2);
}

#valota_container {
  background-color: black;
}

.window_body {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#recovery_id {
  position: absolute;
  bottom: 10px;
  left: 10px;
  opacity: 0.6;
  font-size: 0.9em;
}

#claim_step_2 {
  height: 0;
  overflow: hidden;
  transition: 1s height;
  position: relative;
}

#claim_step_2:before {
  content: "";
  display: block;
  height: 4vmin;
  width: 0;
  border-left: 2px solid rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 50%;
  top: 0;
}

.double_div {
  display: flex;
  justify-content: center;
  position: relative;
  top: 4vmin;
}

.double_div:before {
  content: "";
  position: absolute;
  display: block;
  height: 0;
  top: 0;
  width: 50%;
  left: 25%;
}

.double_one {
  flex: 1;
  padding-top: 4vmin;
  position: relative;
}

#claim_keyboard {
  width: 80vw;
}

.double_one .details {
  font-size: 0.8em;
  padding: 1em;
  color: rgba(0, 0, 0, 0.6);
}

#claim_valota_logo, #pin_valota_logo {
  height: 50vmin;
  width: 90%;
  transition: 1s height;
  background-image: url("valotalive_logo_2019.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.info_div {
  color: rgba(0, 0, 0, 0.5);
  font-size: 1em;
  padding: 1em;
}

.powered_by {
  color: rgba(0, 0, 0, 0.3);
  font-size: 0.8em;
  position: absolute;
  bottom: 1em;
  right: 1em;
}

.powered_by a, .powered_by a:active, .powered_by a:visited, .powered_by a:link {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.5);
}

body:not(.preview) #valota_app_container iframe.active {
  opacity: 1;
  visibility: visible;
  cursor: none;
  z-index: 2;
}

#valota_app_container iframe.inactive {
  opacity: 0;
  visibility: hidden;
  z-index: 0;
}

.error_cont {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.window_body .error {
  margin: 10vmin;
  font-size: 5vmin;
  text-align: center;
}

.error_cont .error {
  color: rgba(255, 255, 255, 0.6);
  margin: 10vmin;
  font-size: 10vmin;
  text-align: center;
}

@keyframes loading_animation {
  0% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(345deg);
  }
}
.wfc {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDYwMCA2MDAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDYwMCA2MDA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KIDxsaW5lYXJHcmFkaWVudCBpZD0iU1ZHSURfMV8iIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4MT0iMjkuMjUiIHkxPSIzMDAiIHgyPSI1NzAuNzUiIHkyPSIzMDAiPgogIDxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9IngxIiAgdmFsdWVzPSIyOS4yNTs1NzAuNzU7MjkuMjUiIGR1cj0iNjEyM21zIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIvPgogIDxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9IngyIiAgdmFsdWVzPSI1NzAuNzU7MjkuMjU7NTcwLjc1IiBkdXI9IjYxMjNtcyIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiLz4KICA8YW5pbWF0ZSBhdHRyaWJ1dGVOYW1lPSJ5MSIgIHZhbHVlcz0iMzAwOzU3MDsyOTk7MzA7MzAwIiBkdXI9IjYxMjNtcyIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiLz4KICA8YW5pbWF0ZSBhdHRyaWJ1dGVOYW1lPSJ5MiIgIHZhbHVlcz0iMzAwOzMwOzI5OTs1NzA7MzAwIiBkdXI9IjYxMjNtcyIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiLz4KICA8c3RvcCBvZmZzZXQ9IjAiIHN0eWxlPSJzdG9wLWNvbG9yOiNEQTFDNUMiLz4KICA8c3RvcCBvZmZzZXQ9IjEiIHN0eWxlPSJzdG9wLWNvbG9yOiNGMzczMzciLz4KIDwvbGluZWFyR3JhZGllbnQ+CiA8cGF0aCBkPSJtIDUxLjYyLDM2Ni4yNSBjIDE4LjI3LDM3LjU1IDQ0Ljk0LDY3LjQ3IDY5LjA5LDg5LjIgNDcuNyw0Mi45MSAxMTMuMDEsNzguMzggMTc0LjcxLDk0LjkgMTUuMTMsNC4wNSAzMC4zNiw2LjA2IDQ1LjU0LDYuMDYgMzEuMzksMCA2Mi41LC04LjYxIDkxLjc3LC0yNS41OSA1NS43NSwtMzIuMzYgOTguNzksLTkyLjkgMTE4LjA3LC0xNjYuMDkgMTUuOTEsLTYwLjQ0IDIyLjI5LC0xMTIuNzcgMTkuMTgsLTE1Ni44NDUgLTEuNjcsLTIzLjM4NSAtNiwtNDQuMzA1IC0xMy4wMiwtNjIuOTI1IC02LjU0LC0xNy4yNyAtMTUuMzEsLTMyLjQgLTI2LjM5LC00NS40MSBDIDQ4Mi42Miw0My4yNiA0MDIuODcsMzguMjkgMzQ0LjQzLDQ0LjAzIDI1Ny44NSw1Mi41NCAzMi40NSwxMDkuMTQgMjkuMywyNjYuODMgYyAtMC42LDMwLjA5IDUuNjk3NDMsNTcuMjM0MTggMTQuNjg1LDgxLjk3IDIuNzIwNjAzLDcuNDg3NzEgMy44MzQzNzksOS45NDg3NiA3LjYzNSwxNy40NSB6IiBzdHlsZT0iZmlsbDp1cmwoI1NWR0lEXzFfKSIvPgo8L3N2Zz4K");
}

#working, #waiting_for_content, #claim_display, #enter_pin, #not_unique {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
}

#waiting_for_content, #claim_display, #working, #enter_pin, #not_unique {
  display: none;
}

.loader_logo {
  width: 10vmin;
  height: 10vmin;
  margin-top: 40vh;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.loading {
  font-size: 8vmin;
  text-transform: uppercase;
  opacity: 0.1;
  margin-top: 2vh;
}

input.tepid {
  background-color: transparent;
  border: 0;
  font-size: 3vmin;
  width: 15em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  text-align: center;
  margin: 0.3em;
  font-family: "Abel", sans-serif;
}

button.btn {
  background-color: transparent;
  border: 0;
  color: rgba(0, 0, 0, 0.7);
  border-radius: 3px;
  padding: 0.3em 0.5em;
  margin: 2px;
  font-size: 1em;
  text-transform: uppercase;
  font-family: "Abel", sans-serif;
  white-space: nowrap;
}

button.btn.right-top {
  top: 0;
  right: 0;
  position: absolute;
}

button.btn:disabled {
  opacity: 0.2;
}

button.btn.small {
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.8em;
}

button.btn.medium {
  font-size: 1.2em;
}

button.btn.large {
  color: rgba(0, 0, 0, 0.6);
  font-size: 1.4em;
  min-width: 6em;
}

button.btn.large:enabled:hover {
  color: rgb(0, 0, 0);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.6);
}

button.btn.large:before {
  font-family: "FontAwesome";
  display: block;
  font-size: 2em;
  text-align: center;
  padding-bottom: 0.5em;
  padding-right: 0;
  opacity: 0.3;
  color: rgb(55, 181, 117);
}

button.btn:before {
  font-family: "FontAwesome";
  display: inline-block;
  padding-right: 0.5em;
  opacity: 0.3;
}

button.btn:after {
  font-family: "FontAwesome";
  display: inline-block;
  padding-left: 0.5em;
  opacity: 0.3;
}

button.btn.after-trash:after {
  content: "\f014";
}

button.btn:enabled:active {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.8);
  border: 0;
  outline: 0;
}

button.btn:enabled:hover {
  color: rgb(0, 0, 0);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
}

button.btn:enabled:before, button.btn:enabled:after {
  opacity: 1;
  color: rgb(55, 181, 117);
}

button.btn.red:enabled:hover:before, button.btn.red:enabled:hover:after {
  color: rgb(229, 0, 25);
}

button.btn.red:enabled:before, button.btn.red:enabled:after {
  color: rgb(229, 0, 25);
}

button.btn.orange:enabled:hover:before, button.btn.orange:enabled:hover:after {
  color: rgb(240, 115, 55);
}

button.btn.orange:enabled:before, button.btn.orange:enabled:after {
  color: rgb(240, 115, 55);
}

button.btn.gray:enabled:hover:before, button.btn.gray:enabled:hover:after {
  color: rgb(220, 220, 220);
}

button.btn.gray:enabled:before, button.btn.gray:enabled:after {
  color: rgb(220, 220, 220);
}

button.btn.subtle:enabled:before, button.btn.subtle:enabled:after {
  color: rgb(220, 220, 220);
}

button.btn:focus {
  border: 0;
  outline: 0;
}

.inline_loader_logo {
  width: 40px;
  height: 40px;
  background-image: url("valota_loading_bg.svg.png");
  background-image: url("valota_loading_bg.svg");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.inline_loader_logo.small {
  width: 30px;
  height: 30px;
}

.inline_loader_logo.active:before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("valota_loading.svg.png");
  background-image: url("valota_loading.svg");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  content: " ";
  display: block;
  position: absolute;
  animation: loading_animation 0.8s ease infinite;
}

#link_working, #login_working {
  color: rgba(0, 0, 0, 0.4);
}

#owner_logo {
  width: 100%;
  height: 40%;
  /*background-image:url('/img/logo_without_bg.svg');*/
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.display_name_cont {
  font-size: 5vmin;
  position: absolute;
  bottom: 2em;
}

#waiting_clock {
  font-size: 2vmin;
  opacity: 0.8;
  padding: 0.8em;
  font-family: monospace;
  position: absolute;
  left: 0;
  bottom: 0;
}

#waiting_clock.hidden {
  display: none;
}

.small_logo {
  vertical-align: middle;
}

.claimed_date {
  font-size: 0.8em;
  display: none;
  padding-top: 0.4em;
  color: rgba(0, 0, 0, 0.4);
}

.content_info {
  display: none;
  position: absolute;
  color: rgba(0, 0, 0, 0.8);
  bottom: 2vmin;
  right: 0;
  left: 0;
  padding-top: 2vmin;
  font-size: 1.2em;
}

#valota_app_container {
  width: 100%;
  width: 100vw;
  height: 0;
  transition: height 0.3s;
  left: 0;
  top: 7.5vmin;
  position: absolute;
  overflow: hidden;
  background-color: white;
}
#valota_app_container iframe {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border: 0;
  padding: 0;
  margin: 0;
  position: absolute;
  background-color: transparent;
}
#valota_app_container.noTitle {
  top: 0;
}
#valota_app_container.show, #valota_app_container iframe {
  /* Logo, title */
  height: calc(100vh - 8.75vmin);
}
#valota_app_container.show.noLogo, #valota_app_container.noLogo iframe {
  /* title */
  height: calc(100vh - 7.5vmin);
}
#valota_app_container.show.noLogo.noTitle, #valota_app_container.noLogo.noTitle iframe {
  /* nothing */
  height: 100vh;
}
#valota_app_container.show.noTitle, #valota_app_container.noTitle iframe {
  /* logo */
  height: calc(100vh - 1.25vmin);
}
#valota_app_container.stats.show, #valota_app_container.stats iframe {
  /* stats,Logo, title */
  height: calc(100vh - 11vmin);
}
#valota_app_container.stats.show.noLogo, #valota_app_container.stats.noLogo iframe {
  /* stats,title */
  height: calc(100vh - 11vmin);
}
#valota_app_container.stats.show.noLogo.noTitle, #valota_app_container.stats.noLogo.noTitle iframe, #valota_app_container.stats.show.noTitle, #valota_app_container.stats.noTitle iframe {
  /* stats */
  height: calc(100vh - 3.5vmin);
}

body.unpaid > div#valota_container > div#valota_app_container.show, body.unpaid > div#valota_container > div#valota_app_container iframe {
  /* Logo, title */
  height: calc(100vh - 18.75vmin);
}
body.unpaid > div#valota_container > div#valota_app_container.show.noLogo, body.unpaid > div#valota_container > div#valota_app_container.noLogo iframe {
  /* title */
  height: calc(100vh - 17.5vmin);
}
body.unpaid > div#valota_container > div#valota_app_container.show.noLogo.noTitle, body.unpaid > div#valota_container > div#valota_app_container.noLogo.noTitle iframe {
  /* nothing */
  height: calc(100vh - 10vmin);
}
body.unpaid > div#valota_container > div#valota_app_container.show.noTitle, body.unpaid > div#valota_container > div#valota_app_container.noTitle iframe {
  /* logo */
  height: calc(100vh - 11.25vmin);
}
body.unpaid > div#valota_container > div#valota_app_container.stats.show, body.unpaid > div#valota_container > div#valota_app_container.stats iframe {
  /* stats,Logo, title */
  height: calc(100vh - 21vmin);
}
body.unpaid > div#valota_container > div#valota_app_container.stats.show.noLogo, body.unpaid > div#valota_container > div#valota_app_container.stats.noLogo iframe {
  /* stats,title */
  height: calc(100vh - 21vmin);
}
body.unpaid > div#valota_container > div#valota_app_container.stats.show.noLogo.noTitle, body.unpaid > div#valota_container > div#valota_app_container.stats.noLogo.noTitle iframe, body.unpaid > div#valota_container > div#valota_app_container.stats.show.noTitle, body.unpaid > div#valota_container > div#valota_app_container.stats.noTitle iframe {
  /* stats */
  height: calc(100vh - 13.5vmin);
}

div#unpaid {
  position: absolute;
  display: none;
  height: 10vmin;
  bottom: 0;
  left: 0;
  width: 100vw;
  background-color: white;
  color: black;
  font-size: 3vmin;
  line-height: 10vmin;
}

body.unpaid > div#unpaid {
  display: inline-block;
}

#header_container {
  height: 7vmin;
  width: 100%;
  top: 0;
  position: absolute;
  right: 0;
  background-color: white;
  transition: width 0.3s;
  overflow: hidden;
}

#now_playing {
  font-size: 4vmin;
  line-height: 7vmin;
  font-weight: bold;
  overflow: hidden;
  padding-left: 3.3vmin;
  box-sizing: border-box;
  top: 0;
  white-space: nowrap;
  position: relative;
  transition: all 0.5s;
  opacity: 1;
  display: inline-block;
  vertical-align: middle;
}

#now_playing.scarce {
  top: -7vmin;
  opacity: 0;
}

#up_next {
  font-size: 3vmin;
  transition: 0.4s all;
  white-space: nowrap;
  right: -100vw;
  top: -16.5vmin;
  color: rgb(0, 0, 0);
  position: absolute;
  background: lightgray;
  border-top-left-radius: 20vmin;
  border-bottom-left-radius: 20vmin;
  height: 40vmin;
  line-height: 40vmin;
  padding-left: 5vmin;
  padding-right: 2.5vmin;
  transition: right 1s linear;
}

#up_next.visible {
  right: 0;
}

#up_next.hidden {
  display: none;
}

#up_next:before {
  content: "Up next: ";
  color: rgba(0, 0, 0, 0.8);
  font-weight: bold;
}

#stats_error {
  fill: rgb(229, 0, 25);
  animation: error_animation 2s ease infinite;
  display: none;
}

#stats_warning {
  fill: #f5da73;
  animation: warning_animation 3s ease infinite;
  display: none;
}

#display_stats {
  position: absolute;
  height: 3.5vmin;
  padding: 0 0.5vmin;
  white-space: nowrap;
  box-sizing: border-box;
  z-index: 10;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  border-bottom: 0.5vmin solid rgb(55, 181, 117);
  color: rgba(255, 255, 255, 0.7);
  font-size: 2vmin;
}

#display_stats.warning {
  border-bottom: 0.5vmin solid #f5da73;
}

#display_stats.warning #stats_warning {
  display: inline-block;
}

#display_stats.error {
  border-bottom: 0.5vmin solid rgb(229, 0, 25);
}

#display_stats.error #stats_error {
  display: inline-block;
}

#stats_online_status {
  display: inline-block;
  vertical-align: middle;
}

#stats_online_status svg {
  height: 3vmin;
  width: 3vmin;
}

#display_stats.play span.bullet, #display_stats.play span#stats_display_started, #display_stats.play span#stats_little_stats {
  display: none;
}

@keyframes warning_animation {
  from {
    transform: scale(1);
  }
  80% {
    transform: scale(1);
  }
  85% {
    transform: scale(1.3);
  }
  90% {
    transform: scale(1.2);
  }
  95% {
    transform: scale(1.3);
  }
  to {
    transform: scale(1);
  }
}
@keyframes error_animation {
  from {
    transform: scale(1);
  }
  80% {
    transform: scale(1);
  }
  85% {
    transform: scale(1.5);
  }
  90% {
    transform: scale(1.3);
  }
  95% {
    transform: scale(1.5);
  }
  to {
    transform: scale(1);
  }
}
#stats_display_name {
  line-height: 3vmin;
}

#stats_display_user {
  font-size: 0.8em;
}

#stats_texts {
  display: inline-block;
  height: 3vmin;
}
#stats_texts span {
  padding-right: 0.2vmin;
}
#stats_texts .hide {
  display: none;
}

#stats_offline_reason.hidden {
  display: none;
}

#stats_display_started, #stats_little_stats, #last_fetches_failed, #stats_offline_reason, .bullet {
  opacity: 0.7;
  font-size: 0.7em;
  line-height: 3vmin;
  height: 3vmin;
}

#last_fetches_failed, #stats_offline_reason {
  padding: 0.1em 0.2em;
  background-color: rgba(229, 0, 25, 0.7);
  color: white;
}

div#display_stats.warning #last_fetches_failed, div#display_stats.warning #stats_offline_reason {
  padding: 0.1em 0.2em;
  background-color: initial;
  color: #f5da73;
}

#stats_display_started:before {
  content: "uptime: ";
}

span.bold, .bullet {
  font-weight: bold;
}

.valota_logo {
  position: absolute;
  z-index: 100;
  bottom: -0.25vmin;
  right: 0.25vmin;
  height: 1.4vmin;
  width: 10vmin;
  background: url(valotalive_logo_one_line_2019.svg) no-repeat right center;
  background-size: contain;
  background-position: right center;
}

div#valota_app_container.stats ~ .valota_logo, div#valota_app_container.stats.noTitle ~ .valota_logo {
  bottom: 1vmin;
  height: 1.6vmin;
}

div#valota_app_container.noLogo ~ .valota_logo {
  display: none;
}

#display_off > .valota_logo {
  animation: valota_anim 20s infinite linear;
  mask: url(valotalive_logo_one_line_2019.svg) no-repeat center right;
  mask-size: contain;
  background: white;
}

@-webkit-keyframes valota_anim {
  0% {
    background-color: white;
  }
  16.7% {
    background-color: lightgray;
  }
  33.4% {
    background-color: darkgray;
  }
  50.1% {
    background-color: black;
  }
  66.7% {
    background-color: darkgray;
  }
  83.4% {
    background-color: lightgray;
  }
  100% {
    background-color: white;
  }
}
@keyframes ContentWaiting {
  0% {
    background-position: 0% 27%;
  }
  50% {
    background-position: 100% 74%;
  }
  100% {
    background-position: 0% 27%;
  }
}
#preview_ontop {
  position: absolute;
  bottom: 1%;
  left: 15%;
  width: 70%;
  padding: 0.5em;
  box-sizing: border-box;
  text-align: center;
  font-size: 2vw;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
}

div#claim_pin:before, div#claim_pin:after {
  font-size: 3vmin;
  color: rgba(0, 0, 0, 0.4);
  line-height: 5vmin;
}

div#claim_pin:before {
  content: "login to https://dashboard.valota.live/#claim with";
  padding-right: 1vmin;
}

div#claim_pin:not(.electron):not(.tizen):after {
  content: "or hover with mouse to enter PIN if you already have one";
  padding-left: 1vmin;
}

div#pin_keyboard {
  display: none;
}

div#qrcode {
  padding: 4vmin;
}

video {
  position: absolute;
  z-index: 9998;
}

#title_clock, #stats_clock {
  font-family: monospace;
  display: none;
}

#stats_clock {
  color: white;
}

#title_clock {
  font-size: 3.5vmin;
  line-height: 7vmin;
  padding: 0 2vmin;
  border-right: 1px solid #ddd;
  color: #888;
  vertical-align: middle;
}

#header_container.clock #title_clock {
  display: inline-block;
}

#display_stats.clock #stats_clock {
  display: inline-block;
}

.preview #stats_offline_reason, .preview #last_fetches_failed {
  display: none;
}

#notification {
  position: absolute;
  z-index: 100;
  bottom: -7vmin;
  opacity: 0;
  height: 7vmin;
  display: flex;
  font-size: 2vmin;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 50vw;
  left: 25vw;
  background-color: white;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
}

#notification.active {
  bottom: 2.5vmin;
  opacity: 1;
}

#notification.active:before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  height: 3px;
  background-color: #eb6e38;
  left: 0;
  width: 100%;
}

#notification.active.checkout:before {
  width: 0%;
  transition: all 6s linear;
}

@keyframes scroll {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
.hidden_elem {
  display: none;
}